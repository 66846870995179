@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    display: flex;
    flex-wrap: wrap;
    container-type: inline-size;

    @include mq.mq($from: medium) {
        flex-wrap: nowrap;
    }
}

.banner {
    width: 100%;
    display: flex;

    &:first-child:not(:last-child) { // First banner of two
        margin: 0 0 var(--spacing-core-6) 0;

        @include mq.mq($from: medium) {
            margin: 0 var(--spacing-core-3) 0 0;
            width: 50%;
        }
    }

    + .banner { // Second banner
        margin: 0;

        @include mq.mq($from: medium) {
            margin: 0 0 0 var(--spacing-core-3);
            width: 50%;
        }
    }

    &:first-child:last-child, // Only one banner
    &:empty + :not(:empty), // First wrapper is empty, but second is not
    &:not(:empty):has(+ :empty) {  // First wrapper is not empty, but second is
        margin: 0 auto;
        width: 100%;

        @include mq.mq($from: large) {
            &.full {
                max-width: 951px;
                width: 79.6%;
            }
        }
    }

    &:empty {
        display: none;
    }
}
